<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.promoOrder.list')"
    class="px-5 py-3"
  >
    <div class="d-flex">
      <base-dropdown-filter
        :items="statusFilters"
        @changeFilter="changeStatusFilter"
        label="Status Promo"
      >
      </base-dropdown-filter>
    </div>
    <div class="mt-2">{{ $t('promoOrder.total') }}: {{ meta.total }}</div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('promoOrder.orderNumber') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.orderStatus') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.agentEmail') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.productName') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.promoCode') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.paidAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.priceBeforePromoDiscount') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.salesName') }}
          </th>
          <th class="primary--text">
            {{ $t('promoOrder.updatedAt') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`agent-banner-${index}`">
          <td>{{ index + 1 }}</td>
          <td>{{ item.order_number }}</td>
          <td>{{ item.order_status }}</td>
          <td>{{ item.agent_email }}</td>
          <td>{{ item.product_name }}</td>
          <td>
            <router-link :to="`/promo-management/promos/edit/${item.promo_uuid}`">{{
              item.promo_code
            }}</router-link>
          </td>
          <td>{{ currencyFormat(item.paid_amount) }}</td>
          <td>{{ currencyFormat(item.price_before_promo_discount) }}</td>
          <td>{{ item.sales_name }}</td>
          <td>
            {{ item.updated_at ? format(new Date(item.updated_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'statusFilters'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.banner.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeStatusFilter(val) {
      this.$emit('changeStatusFilter', val);
    },
  },
};
</script>
